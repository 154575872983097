import { skipHydrate, storeToRefs } from 'pinia';
import { useBattlesDataStateStore } from './dataState.store';
import { useSingleBattleStore } from './singleBattle.store';
import type {
  IBattlesBattlesListGetQuery,
  IBattlesShortInfoBattleEntity,
  ICaseInBattleWithCaseId,
} from '~/repository/modules/battles';
import { EBattleStatuses } from '~/repository/modules/battles';
import { useUserStore } from '~/store/user/user.store';
import { useBattlesListFiltersSortingStore } from '~/features/battles/store/battlesListFilters.store';
import type { IBattlesBattleShortItemInStore } from '~/features/battles/types/battlesStore.types';
import { useCreateBattleOptionsStore } from '~/features/battles/store/createBattleOptions.store';
import { useCasesSelectionStore } from '~/features/battles/store/casesSelection.store';
import { EBattlesListSortingVariants, EBattlesStoreNames } from '~/features/battles/constants/general';
import type { IBattlesErrorCode16 } from '~/repository/modules/battles/BattlesErrors.types';
import { useAlertStore } from '~/store/alert/alert.store';
import type { TPossibleNull } from '~/types/Shared.types';
import { BattleEvents } from '~/repository/amplitude/events/battle';

const { MAIN, HISTORY, STATISTICS } = ROUTING.BATTLES;

/* Хранилище списка батлов */
export const useBattlesListStore = defineStore(EBattlesStoreNames.BATTLES_LIST, () => {
  /* -- Imports -- */
  const localeRoute = useLocaleRoute();
  /* Импорт апи модуля */
  const {
    $api: { battles: BattlesApiService },
    $i18n: { t },
  } = useNuxtApp();

  /* Импорт других хранилищ, для работы */
  const alertStore = useAlertStore();
  const listFiltersStore = useBattlesListFiltersSortingStore();
  const singleBattleStore = useSingleBattleStore();
  const userStore = useUserStore();
  const dataStateStore = useBattlesDataStateStore();

  /* -- Initialisation -- */

  /* Получение id юзера (userId), если пользователь авторизован */
  const { userId } = storeToRefs(userStore);
  const currentUserBattleListLength = ref<number | undefined>(0);
  /* Коллекция краткой информации о батлах, для таблицы батлов */
  const battlesList = ref<IBattlesBattleShortItemInStore[]>([]);
  /* Минималистичные query-параметры для динамической загрузки списка для разных страниц */
  const battlesQuery = ref<Partial<IBattlesBattlesListGetQuery>>({
    statuses: [EBattleStatuses.STARTED, EBattleStatuses.CREATED],
  });
  /* Нужно ли загрузить еще одну страницу списка батлов */
  const battlesListLoadMore = ref(false);
  /* Идет ли загрузка списка батлов (вне зависимости от страницы) */
  const isListLoading = ref(true);
  /* Флаги загрузки кнопок для таблицы батлов */
  const tableButtonsState = reactive<{
    /* ID батла, к которому подключаемся */
    isJoiningByActionButton: TPossibleNull<string>;
    /* Подключаемся к батлу через модалку */
    isJoiningViaModal: boolean;
    /* ID батла, который покидаю */
    isLeavingViaActionButton: TPossibleNull<string>;
  }>({
    isJoiningByActionButton: null,
    isJoiningViaModal: false,
    isLeavingViaActionButton: null,
  });

  /* -- Actions -- */

  /* Получение списка батлов по API */
  const fetchBattles = async () => {
    try {
      const { battles, hasNext } = await BattlesApiService.getBattlesList(battlesQuery.value);

      battlesListLoadMore.value = hasNext;

      return battles;
    } catch {}
  };

  /* Функция присоединения к батлу */
  const joinBattleInTable = async (slot: number, battle: IBattlesBattleShortItemInStore) => {
    if (!battle) return;

    try {
      const result = await BattlesApiService.joinBattle({
        battleUlid: battle.ulid,
        slot,
      });

      if (result.balance) userStore.setNewBalance(result.balance);

      BattleEvents.participantEntered({ 'Battle Price': battle.price, 'Rounds': battle.rounds });

      singleBattleStore.joiningFromTable = true;

      await navigateTo(localeRoute(ROUTING.BATTLES.BATTLE.getDynamicPath(battle.ulid)));
    } catch (e) {
      const error = e as IBattlesErrorCode16;

      if (error.message && error.code && error.battleUlid) {
        alertStore.showError({
          message: t('battles.error.alreadyInBattle'),
          title: t('alerts.joiningBattle'),
        });
        await navigateTo(localeRoute(ROUTING.BATTLES.BATTLE.getDynamicPath(error.battleUlid)));
      }
    }
  };

  /* Получаем список всех батлов и преобразуем в плоскую коллекцию батлов */
  const fetchAllBattles = async (myPrivate = false) => {
    battlesQuery.value = {
      private: myPrivate ? 1 : 0,
      statuses: [EBattleStatuses.STARTED, EBattleStatuses.CREATED],
    };

    isListLoading.value = true;
    const battles = await fetchBattles();
    isListLoading.value = false;

    if (Array.isArray(battles)) {
      updateMyBattleList(battles);
      parseBattles(battles, true);
      sortList([...battlesList.value]);
    }
  };

  /* Получаем список батлов, относящихся к определенному пользователю и преобразуем в плоскую коллекцию батлов */
  const fetchMyBattles = async () => {
    if (!userId.value) return;

    battlesQuery.value = {
      limit: 10,
      page: 1,
      private: 1,
      sortBy: EBattlesListSortingVariants.DATE,
      sortDesc: 1,
      statuses: [EBattleStatuses.STARTED, EBattleStatuses.CREATED, EBattleStatuses.FINISHED],
      userId: userId.value,
    };

    isListLoading.value = true;
    const battles = await fetchBattles();
    isListLoading.value = false;

    Array.isArray(battles) && parseBattles(battles, true);
  };

  /* Получить ВСЕ батлы определенного пользователя и вернуть кол-во их */
  const fetchAllMyBattles = async () => {
    if (!userId.value) return currentUserBattleListLength.value;

    battlesQuery.value = {
      limit: 10000,
      page: 1,
      private: 1,
      sortBy: EBattlesListSortingVariants.DATE,
      sortDesc: 1,
      statuses: [EBattleStatuses.STARTED, EBattleStatuses.CREATED, EBattleStatuses.FINISHED],
      userId: userId.value,
    };

    isListLoading.value = true;
    const battles = await fetchBattles();
    isListLoading.value = false;

    currentUserBattleListLength.value = battles?.length;

    return currentUserBattleListLength.value;
  };

  /* Получаем список батлов из истории и преобразуем в плоскую коллекцию батлов */
  const fetchHistoryBattles = async () => {
    listFiltersStore.clearFilters();
    battlesQuery.value = {
      limit: 10,
      page: 1,
      private: 0,
      sortBy: EBattlesListSortingVariants.DATE,
      sortDesc: 1,
      statuses: [EBattleStatuses.FINISHED],
    };

    isListLoading.value = true;
    const battles = await fetchBattles();
    isListLoading.value = false;

    Array.isArray(battles) && parseBattles(battles, true);
  };

  /* Функция создания батла на основе другого */
  const createTheSameBattle = (battle: IBattlesBattleShortItemInStore, targetFrom: string) => {
    const { slots, cases, bots, private: privateFlag } = battle;

    const createBattleOptionsStore = useCreateBattleOptionsStore();

    createBattleOptionsStore.resetOptions();

    createBattleOptionsStore.optionsState.slotsAmount = slots;
    createBattleOptionsStore.optionsState.isBattlePrivate = privateFlag;
    createBattleOptionsStore.optionsState.startWithBots = bots;

    useCasesSelectionStore().prepareSelectedCasesFromOthers(cases);

    const targets = {
      'battles/history': 'battle history',
      'battles/main': 'battle result',
      'battles/statistics': 'my battles',
    };
    const buttonPlace = targets[targetFrom as keyof typeof targets];

    if (buttonPlace) {
      BattleEvents.sameBattleCreated({ 'Button Place': buttonPlace });
    }

    return navigateTo(localeRoute(ROUTING.BATTLES.CREATE));
  };

  /* Функция-обработчик для всех приходящих батлов через сокеты */
  function handleBattlesListener(data: { battle: IBattlesShortInfoBattleEntity }) {
    const route = useRoute();
    if (!data || !data.battle) return;

    const { battle: updatedBattle } = data;

    const findBattleIndex = battlesList.value.findIndex((battle) => updatedBattle.ulid === battle.ulid);
    const isBattleExisted = findBattleIndex !== -1;

    const isStatisticPage = route.name === STATISTICS.name;
    const isMainPage = route.name === MAIN.name;
    const isHistoryPage = route.name === HISTORY.name;

    /* Сравниваем прошлый батл и новый в виде строк */
    let isBattleTheSameOne = false;
    if (isBattleExisted) {
      isBattleTheSameOne = JSON.stringify(battlesList.value[findBattleIndex]) === JSON.stringify(updatedBattle);
    }

    /* Если прошлый батл и новый отличаются  */
    if (userId.value && !isBattleTheSameOne) {
      const { members, status } = updatedBattle;

      const wasMeInBattle = !!members.find((member) => member.userId === userId.value);

      /* Если батл закончился и "я" в нем состоял, то получаю пользователя, чтобы обновить баланс */
      if (wasMeInBattle && status === EBattleStatuses.FINISHED) {
        userStore.getMe();
      }
    }

    if (!isBattleTheSameOne) {
      const parsedBattle = getParsedBattle(updatedBattle);
      let changedProperty: (keyof IBattlesBattleShortItemInStore)[] = [
        'my',
        'hostId',
        'members',
        'spectators',
        'slotsWithMembers',
        'status',
        'slots',
        'isMeInBattle',
      ];

      if (isMainPage) {
        if (!isBattleExisted && updatedBattle.status === EBattleStatuses.CREATED && !updatedBattle.private) {
          // Добавил проверку, приватный ли батл, чтобы не показывать в списке батлов вообще
          sortList([parsedBattle, ...battlesList.value]);
          filterList();
        } else if (
          isBattleExisted &&
          (updatedBattle.status === EBattleStatuses.FINISHED || !!updatedBattle.cancellationReason)
        ) {
          battlesList.value.splice(findBattleIndex, 1);
        } else {
          changedProperty.forEach((item) => {
            if (isBattleExisted && item in battlesList.value[findBattleIndex]) {
              // @ts-ignore (sorry)
              battlesList.value[findBattleIndex][item] = parsedBattle[item];
            }
          });
        }
      }

      if (isStatisticPage) {
        changedProperty = ['winnerId', 'total', 'members', 'slotsWithMembers', 'status', 'spectators', 'slots'];
        if (!isBattleExisted && updatedBattle.status === EBattleStatuses.CREATED) {
          battlesList.value.unshift(parsedBattle);
        } else if (!parsedBattle.isMeInBattle) {
          battlesList.value.splice(findBattleIndex, 1);
        } else {
          changedProperty.forEach((item) => {
            // @ts-ignore (sorry)
            battlesList.value[findBattleIndex][item] = parsedBattle[item];
          });
        }
      }

      if (isHistoryPage) {
        if (!isBattleExisted && updatedBattle.status === EBattleStatuses.FINISHED) {
          battlesList.value.unshift(parsedBattle);
        }
      }
    }
  }

  /* Обновлять список батлов для отображения батла юзера сверху  */
  const updateMyBattleList = (battles: IBattlesShortInfoBattleEntity<ICaseInBattleWithCaseId>[]) => {
    if (!Array.isArray(battles) && !dataStateStore.myState.activeBattleUlid) return;

    const activeBattleIndex = battles.findIndex((battle) => battle.my);

    if (activeBattleIndex && activeBattleIndex !== -1) {
      const deletedBattle = battles?.splice(activeBattleIndex, 1);
      deletedBattle && battles?.unshift(deletedBattle[0]);
    }

    if (battles) parseBattles(battles, true);
  };

  /* Функция подгрузки дополнительного числа батлов */
  const loadMoreBattles = async () => {
    if (!battlesQuery.value.page) return;
    battlesQuery.value.page++;
    try {
      const battles = await fetchBattles();
      Array.isArray(battles) && parseBattles(battles, false);
    } catch {}
  };

  /* Функция сортировки батлов */
  function sortList(oldBattlesList: IBattlesBattleShortItemInStore[]) {
    const { sortBy, sortDesc } = listFiltersStore.sorting;
    const sortByDate = (a: IBattlesBattleShortItemInStore, b: IBattlesBattleShortItemInStore) => {
      return sortDesc ? b.ulid.localeCompare(a.ulid) : a.ulid.localeCompare(b.ulid);
    };

    const sortByPrice = (a: IBattlesBattleShortItemInStore, b: IBattlesBattleShortItemInStore) => {
      let result;
      const typedSortBy = sortBy as keyof IBattlesBattleShortItemInStore;
      if (sortDesc) result = Number(a[typedSortBy]) < Number(b[typedSortBy]);
      else if (!sortDesc) result = Number(a[typedSortBy]) > Number(b[typedSortBy]);
      else return 0;

      return result ? 1 : -1;
    };

    const sortObj = {
      [EBattlesListSortingVariants.PRICE]: sortByPrice,
      [EBattlesListSortingVariants.DATE]: sortByDate,
    };

    let newBattlesList: IBattlesBattleShortItemInStore[] = [];

    // логика подстановки нашего батла на первое место (если мы в батле)
    if (dataStateStore.myState.inBattle) {
      // создать буферный массив для оптимизации перерисовки после сортировки

      // найти индекс нашего батла
      const myBattleIndex = oldBattlesList.findIndex((item) => item.ulid === dataStateStore.myState.activeBattleUlid);

      // делаем наш батл первым
      newBattlesList.push({ ...oldBattlesList[myBattleIndex] });

      // группируем остальные батлы через slice, вырезая наш батл
      const otherList = [...oldBattlesList.slice(0, myBattleIndex), ...oldBattlesList.slice(myBattleIndex + 1)];

      otherList.sort(sortObj[sortBy]);

      // в свободное от тасок время, перерисовываем список
      nextTick(() => {
        battlesList.value = newBattlesList.concat(...otherList);
      });
    } else {
      // в свободное от тасок время, сортируем список
      newBattlesList = [...oldBattlesList];
      newBattlesList.sort(sortObj[sortBy]);
      nextTick(() => {
        battlesList.value = newBattlesList;
      });
    }
  }

  /* Функция фильтрация батлов */
  function filterList() {
    battlesList.value.forEach((battle) => {
      battle.hidden = false;
      if (listFiltersStore.filters.availableByBalance) {
        battle.hidden = userStore.userBalance < battle.price;
      }
      if (!battle.hidden && listFiltersStore.filters.hideActive) {
        battle.hidden = battle.status === EBattleStatuses.STARTED;
      }
    });
  }

  /* -- Helpers -- */

  /* Функция, преобразующая массив батлов с сервера в массив батлов для работы в сторе */
  const parseBattles = (battles: Array<IBattlesShortInfoBattleEntity>, rewrite = false) => {
    if (rewrite) {
      battlesList.value = [];
    }
    battles.forEach((shortBattle) => {
      battlesList.value.push(getParsedBattle(shortBattle));
    });
    filterList();
  };

  /* Функция, которая возвращает преобразованнный батл */
  const getParsedBattle = (shortBattle: IBattlesShortInfoBattleEntity) => {
    const result = {
      ...shortBattle,
      cases: [],
      hidden: false,
      isMeInBattle: !!shortBattle.members.find((member) => member.userId === userId.value),
      rounds: 0,
      slotsWithMembers: {},
    } as IBattlesBattleShortItemInStore;

    result.cases = shortBattle.cases.map(({ data, amount }) => {
      result.rounds += amount || 0;

      return {
        ...data,
        amount,
      };
    });

    for (let i = 1; i <= shortBattle.slots; i++) {
      const memberBySlot = shortBattle.members.find((member) => member.slot === i);
      const isWinner = memberBySlot?.id === shortBattle.winnerId;

      result.slotsWithMembers[i] = null;

      if (memberBySlot) {
        result.slotsWithMembers[i] = {
          ...memberBySlot,
          isWinner,
        };
      }
    }
    return result;
  };

  /* Side Effects */

  /* Получили юзера. Если он есть, проверяю батлы на "мои" */
  watch(
    () => userId.value,
    (userIdValue) => {
      if (!userIdValue) return;
      setTimeout(() => {
        battlesList.value.forEach((battle) => {
          battle.isMeInBattle = !!battle.members.find((member) => member.userId === userId.value);
        });
      });
    },
  );

  /* -- Returns -- */
  return {
    battlesList: skipHydrate(battlesList),
    battlesListLoadMore,
    createTheSameBattle,
    currentUserBattleListLength,
    fetchAllBattles,
    fetchAllMyBattles,
    fetchHistoryBattles,
    fetchMyBattles,
    filterList,
    handleBattlesListener,
    isListLoading,
    joinBattleInTable,
    loadMoreBattles,
    sortList,
    tableButtonsState,
  };
});
